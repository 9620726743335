import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Slider from "react-slick";
import TdtImage from "./TdtImage";
import TdtButton from "./TdtButton";
import TdtIcon from "./TdtIcon";
import { HiOutlineChatAlt } from "react-icons/hi";
import { WebApi } from "shared/WebApi"; 
const projectpath = process.env.REACT_APP_API_URL;

interface adventureindubaiprops { } 
const TdtTravelPkg: React.FC<adventureindubaiprops> = (props) => {
    const [tdtTravelPkgs, setTdtTravelPkgs] = useState<any[]>([]);  
    const getReasons = async () => {   
      try {
        const response = await WebApi('get', 'api/get_popular_categories');
        const responseData: any = response;
        if (responseData.status === 200) {  
          const modifiedData:any = responseData.data.data  
          setTdtTravelPkgs(modifiedData);  
          debugger
        }
      } catch (error:any) {
        console.error('Error:'+ error);  
      } 
    }; 
    useEffect(() => { 
      getReasons();
    }, []);
   
{/* <a href={`tours/${item.pId}`}>
<div className="card-setsider px-2">
<TdtImage preview={false} src={projectpath + "/public/panelassets/images/uploads/" + item.package_image_thumbnail} alt={item.title}/>

 */}

  return (
    <Container fluid  className='TdtTravelPkg py-5'>
        <Container  className=''>
        
            <Row className="TdtTravelPkg-container   pb-5">
                
                <Col sm={12} className="d-flex justify-content-between pb-3">
                <h5 className="setheadingrow hightlight">Most Popular</h5>
                <h5 className="setheadingrow">Activities In Dubai</h5>
                {/* <TdtButton label="View All" href="#" /> */}
                </Col> 
                {tdtTravelPkgs.map((item:any, i:any) => ( 
                    <Col className="pb-3" sm={12} md={3} >
                        <a className="planecolumn" href={`tours/category/${item.cat_id}`}>
                            <TdtImage preview={false} src={process.env.PUBLIC_URL  + 'assets/airoplanedbd.svg'}/>    
                            <div className="trvlpkg-content" style={{backgroundImage: `url( ${projectpath+"/public/panelassets/images/uploads/"+item.image} )`}}>
                                <div className="txtcontent-trvlpkg">
                                    {item.title}
                                </div>
                                <div className="plncolmoverlays"></div> 
                            </div>   
                        </a> 
                    </Col> 
                ))}  
            </Row>
        
        </Container>
    </Container>
  );
}

export default TdtTravelPkg;
