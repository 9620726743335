import { Button, Form, Input, Space } from 'antd';
import { FormInstance } from 'antd/es/form'; 
import TdtDrawer from 'components/websitelayout/TdtDrawer';
import React, { useState } from 'react'; 
import moment from 'moment'; 
import { Col, Row } from 'react-bootstrap'; 
import { Table } from 'antd';
import { TableColumnsType, TableProps, message, Upload } from 'antd'; 
import TdtTable from 'components/websitecomponents/TdtTable'; 
import TdtInput from 'components/websitecomponents/TdtInput';
import TdtButton from 'components/websitecomponents/TdtButton';
import { HiUpload } from 'react-icons/hi';
import type { UploadProps } from 'antd';
import TdtIcon from 'components/websitecomponents/TdtIcon';
import { FaPen, FaPenToSquare, FaRegTrashCan } from 'react-icons/fa6';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

type TableRowSelection<T> = TableProps<T>['rowSelection']; 
interface TdtBookDrwerprops {
  open?:boolean 
  onClose?:any 
  title?:string
  destroyOnClose?: boolean
  placement?:string
} 
interface DataType {
  key: any;
  banner_heading: string;
  banner_description: string;
  banner_image: string; 
}  
const tabledata: DataType[] = [];
for (let i = 0; i < 5; i++) {
  tabledata.push({
    key: i,
    banner_heading: `Tour Option `,
    banner_description: `AED 100 for ${i} Person(s)`,
    banner_image: `qty_adults. `, 
  });
}  
const DrwrWhoWeAre: React.FC<TdtBookDrwerprops> = (props) => {
  const [form] = Form.useForm()
  const formRef = React.useRef<FormInstance>(null);
  const onReset = () => {
      formRef.current?.resetFields();
  };   
const tblheadcolumns: TableColumnsType<DataType> = [
    {
      title: 'Heading',
      dataIndex: 'banner_heading',
      width:'280px'
    },
    {
      title: 'Description',
      dataIndex: 'banner_description',
      width:'380px'
    },
    {
      title: 'Image',
      dataIndex: 'banner_image',   
    }, 
    {
      title: 'Action',
      width:'100px',
      dataIndex: 'banner_image',   
      render: (_, record) => (
        <div className='d-flex'>
           <TdtIcon icon={<FaPenToSquare />}  />
           <TdtIcon icon={<FaRegTrashCan />}  />
        </div>
      ),
    } 
  ];
 
  const onFinish = (values: any) => {  
    values.start_date = moment(values.start_date).format("DD-MM-YYYY") 
    console.log(values)
  }
   
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo); 
  }; 
    

  const uploadProps: UploadProps = {
    name: 'file',
    action: `${process.env.PUBLIC_URL  + 'assets'}`,
    headers: {
      authorization: 'authorization-text',
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };
  
  const [uploadfile, setuploadfile] = useState<File | null>(null);
  const handleCompanyLogoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    setuploadfile(file);   
  };
  return (
        <TdtDrawer title={props.title}  open={props.open} placement={props.placement} onClose={props.onClose} width={'1250px'} destroyOnClose={props.destroyOnClose}>
            <Form
              name="dynamic_form_nest_item"
              form={form}
              ref={formRef}
              // initialValues={initialValues}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
              layout="vertical"
            >  
              <Form.List name="multipleBanners">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }) => (
                      <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                        <Form.Item
                          {...restField}
                          name={[name, 'Banner']}
                          rules={[{ required: true, message: 'Missing first name' }]}
                        >
                          <Input  type="file"  onChange={handleCompanyLogoChange}  placeholder="First Name" /> 
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, 'Logo']}
                          rules={[{ required: true, message: 'Missing lasr name' }]}
                        > 
                          <Input  type="file"  onChange={handleCompanyLogoChange}  placeholder="First Name" /> 
                        </Form.Item> 
                        <MinusCircleOutlined onClick={() => remove(name)} />
                      </Space>



                    ))}
                    <Form.Item>
                      <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                        Add field
                      </Button>
                    </Form.Item>
                  </>
                )}
              </Form.List> 
      
      <Button type="primary" htmlType="submit">
        Submit
      </Button> 
                 
              </Form>  
            <Row>
                <Col sm={12}>
                    <TdtTable  pagination={false} tblheadcolumns={tblheadcolumns} tabledata={tabledata} />  
                </Col>  
            </Row>
        </TdtDrawer>
  );
}

export default DrwrWhoWeAre;