import { Form } from 'antd';
import { FormInstance } from 'antd/es/form'; 
import TdtDrawer from 'components/websitelayout/TdtDrawer';
import React, { useEffect, useState } from 'react'; 
import moment from 'moment'; 
import { Col, Row } from 'react-bootstrap'; 
import { Table } from 'antd';
import { TableColumnsType, TableProps, message, Upload } from 'antd'; 
import TdtTable from 'components/websitecomponents/TdtTable'; 
import TdtInput from 'components/websitecomponents/TdtInput';
import TdtButton from 'components/websitecomponents/TdtButton';
import { HiUpload } from 'react-icons/hi';
import type { UploadProps } from 'antd';
import TdtIcon from 'components/websitecomponents/TdtIcon';
import { FaRegTrashCan, FaPenToSquare } from 'react-icons/fa6';
import { LiaCommentsDollarSolid } from 'react-icons/lia';
import { WebApi } from 'shared/WebApi';
type TableRowSelection<T> = TableProps<T>['rowSelection']; 
interface TdtBookDrwerprops {
  open?:boolean 
  onClose?:any 
  title?:string
  destroyOnClose?: boolean
  placement?:string
} 
interface DataType {
  key: any;
  title: string; 
  dataIndex: string,
}  


const DrwrAddReasons: React.FC<TdtBookDrwerprops> = (props) => {
  const [form] = Form.useForm()
  const formRef = React.useRef<FormInstance>(null);
  const onReset = () => {
      formRef.current?.resetFields();
  };   

    
const tabledata = [
  {
    key: '1',
    name: 'BEST PRICE GUARANTEE', 
  },
  {
    key: '2',
    name: '24X7 LIVE CHAT SUPPORT', 
  },
  {
    key: '3',
    name: 'FAST BOOKING', 
  },
  {
    key: '4',
    name: '5 STAR FACILITIES', 
  },
  {
    key: '5',
    name: 'WIFI COMING SOON', 
  },
];

const tblheadcolumns : TableColumnsType<DataType> = [
  { 
    title: 'Reason',
    dataIndex: 'reason', 
  },
  {
    title: 'Action',
    key: 'action',
    render: (_:any, record:any) => (
      <a onClick={() => deleteReason(record.reasonId)}>Delete</a>
    ),
    // title: 'Action',  
    // width:'100px',
    // render: (_, record) => (
    //   <div className='d-flex'>
    //      <TdtIcon icon={<FaPenToSquare />}  />
    //      <TdtIcon icon={<FaRegTrashCan />}  />
    //   </div>
    // ),
  } 
];  
 
  const onFinish = async (values: any) => {       
    try {
      const response = await WebApi('post', 'api/add_reasons', values);  
      const responseData: any = response; 
      if (responseData.status === 200) {  
        message.success('Reason added');
        getReasons(); 
      } else {
        console.error('Error: Request error');  
      }
    } catch (error:any) {
      console.error('Error:', error);  
    }
  };
   
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo); 
  }; 
    
 


  const [getAllReasons, setGetAllReasons] = useState<any[]>([]);  
  const getReasons = async () => {   
    try {
      const response = await WebApi('get', 'api/get_all_reasons');
      const responseData: any = response;
      if (responseData.status === 200) {  
        const modifiedData:any = responseData.data.data  
        setGetAllReasons(modifiedData);  
      }
    } catch (error:any) {
      console.error('Error:'+ error);  
    } 
  }; 
 
  const deleteReason = async (reason_id: any) => {
    try { 
      const response = await WebApi('post', 'api/delete_reason', { reason_id: reason_id });
      const responseData: any = response; 
      debugger
      if (responseData.status === 200) { 
        message.success('Reason deleted successfully');
        getReasons();  
      }
    } catch (error:any) {
      console.error('Error:'+error);  
    } 
  };

  useEffect(() => {
    if (props.open) {
      getReasons();
    }
  }, [props.open]);



  
  return (
        <TdtDrawer title={props.title}  open={props.open} placement={props.placement} onClose={props.onClose} width={'750px'} destroyOnClose={props.destroyOnClose}>
            <Form
                name="add-designation-form"
                form={form}
                ref={formRef}
                // initialValues={initialValues}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
                layout="vertical"
            >
              <Row>
                <Col sm={12} md={4}>
                  <TdtInput label='Add Reason' name='reason' />
                </Col>  
                <Col sm={12} md={6}>
                    <TdtButton label={'Save'} htmlType="submit" classNamebtn='me-2 px-5 mt-4'/>
                </Col>  
              </Row>
            </Form>
            <Row>
                <Col sm={12}>
                    <TdtTable  pagination={false} tblheadcolumns={tblheadcolumns} tabledata={getAllReasons} />  
                </Col>  
            </Row>
        </TdtDrawer>
  );
}

export default DrwrAddReasons;