import { Layout, Menu, MenuProps } from 'antd';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import headerlogo from "../../assets/images/deluxetravellogo.svg";
import { Col, Container, Row } from 'react-bootstrap';
import { WebApi } from 'shared/WebApi'; 
import { useNavigate } from 'react-router-dom';

interface Homeprops {
className?: string
}

const { Header } = Layout;
type MenuItem = Required<MenuProps>['items'][number];
function getItem(
    label: React.ReactNode,
    key?: React.Key | null,
    icon?: React.ReactNode,
): MenuItem {
    return {
        key,
        icon,
        label,
    } as MenuItem;
}

const itemsMenuAdmin: MenuItem[] = [
    getItem(<Link to="/desert-safari-dubai-tours"> Desert Safari  </Link>, ' Desert Safari '),
    getItem(<Link to="/sight-seeing-dubai-tours"> Sight Seeing </Link>, ' Sight Seeing  '),
    getItem(<Link to="/private-tours-dubai-tours"> Private Tours </Link>, ' Private Tours '),
    getItem(<Link to="/executive-dubai-tours"> Executive </Link>, ' Executive'),  
    getItem(<Link to="/dhow-cruise-dubai-tours"> Dhow Cruise </Link>, ' Dhow Cruise '), 
    getItem(<Link to="/dhow-cruise-dubai-tours"> Theme Park </Link>, ' Theme Park '), 
    getItem(<Link to="/dhow-cruise-dubai-tours"> Sky Tours </Link>, ' Sky Tours '), 
    getItem(<Link to="/dhow-cruise-dubai-tours"> Sea Advantures </Link>, 'Sea Advantures'), 
    getItem(<Link to="/dhow-cruise-dubai-tours"> Quad Biking </Link>, 'Quad Biking'), 
];


export const HeaderNavbar: React.FunctionComponent<Homeprops> = (props) => {
    const navigate = useNavigate(); 
    const [categoriesList, setCategoriesList] = useState('') 
    useEffect(() => {  
        getCategories()
    }, []);
  
    const getCategories = async () => {  
        try {
            const response = await WebApi('get', 'api/get_all_categories'); 
            const responseData: any = response; 
            if (responseData.status === 200) { 
                const modifiedData = responseData.data; 
                if (modifiedData.status === true) {   
                    // alert('headerNavbar'+modifiedData.data[0].title)
                }
                else {
                    console.error('Error:', modifiedData.message);  
                }
            } else {
                console.error('Error: Request error');  
            }
            } catch (error:any) {
          console.error('Error:', error);  
        }
    }; 
    return (
        <Header className={`setheaderweb ${props.className}`}>
            <Container fluid className='p-0'>
            <Container>
                    <Row>
                        <Col sm={3}>
                            <img src={headerlogo} alt="" className='w-100'></img>
                        </Col> 
                        <Col sm={3}>
                             
                        </Col> 
                        <Menu theme="dark" mode="horizontal" defaultSelectedKeys={['1']} items={itemsMenuAdmin} style={{ flex: 1, minWidth: 0 }} className='headertours' />
                    </Row>  
                </Container >
            </Container >

        </Header>
    )
}

export default HeaderNavbar; 