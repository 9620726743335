import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Badge, Card } from "antd";
import TdtImage from "./TdtImage";
import TdtButton from "./TdtButton";
import TdtIcon from "./TdtIcon";
import { AiOutlineClockCircle } from "react-icons/ai";
import { LiaCommentsDollarSolid } from "react-icons/lia";
import { useNavigate } from "react-router-dom";
import { WebApi } from "shared/WebApi";

const projectpath = process.env.REACT_APP_API_URL;

interface Tdttourspackageprops {}

const TdtToursPackage: React.FC<Tdttourspackageprops> = () => {
  const navigate = useNavigate();
  
  const [getAllProducts, setGetAllProducts] = useState<any[]>([]);

  // Function to fetch products/packages
  const getProducts = async () => {
    try {
      const response = await WebApi('post', 'api/get_package');
      const responseData: any = response;
      if (responseData.status === 200) {
        const packagesArray = Object.values(responseData.data.data);
        setGetAllProducts(packagesArray);
        debugger
      }
    } catch (error: any) {
      console.error('Error:', error);
    }
  };

  // Fetch products on component mount
  useEffect(() => {
    getProducts();
  }, []);

  // Group products/packages by their cat_id
  const groupProductsByCategory = (products: any[]) => {
    return products.reduce((acc, product) => {
      const categoryId = product.cat_id;
      if (!acc[categoryId]) {
        acc[categoryId] = {
          cat_title: product.cat_title,
          products: [],
        };
      }
      acc[categoryId].products.push(product);
      return acc;
    }, {} as any);
  };

  // Group the products by their categories
  const groupedProducts = groupProductsByCategory(getAllProducts);

  return (
    <Container className="homecatpkgs">
      {/* Iterate over grouped categories */}
      {Object.keys(groupedProducts).map((categoryId: any) => {
        const category = groupedProducts[categoryId];

        return (
          <Row className="TdtToursPackage-row py-5" key={categoryId}> 
            <Col sm={12} className="d-flex justify-content-between pb-3">
                <h5 className="setheadingrow hightlight"> {category.cat_title}</h5>
                <h5 className="setheadingrow">Adventure In Dubai</h5>
                <TdtButton label="View All" href={''} />
            </Col>
            {/* Render each product within this category */}
            {category.products.map((tourpkg: any, index: number) => (
              <Col className="p-0" sm={12} md={3} key={index}>
                <Badge.Ribbon text={`${tourpkg.discount_price} OFF`}>
                  <a href={`tours/${tourpkg.pid}`}>
                    <Card title={tourpkg.title} size="small">
                      <TdtImage
                        preview={false}
                        src={
                          projectpath +
                          "/public/panelassets/images/uploads/" +
                          tourpkg.package_image_thumbnail
                        }
                      />
                      <div className="pmmentduratoin d-flex justify-content-between">
                        <div className="trvlduration">
                          <div className="startdurationtxt">Duration</div>
                          <TdtIcon
                            icon={<AiOutlineClockCircle />}
                            label={`${tourpkg.duration} ${tourpkg.duration_type} `}
                          />
                        </div>
                        <div className="trvlpayment">
                          <div className="startpamntfrtxt">Start From</div>
                          <TdtIcon
                            icon={<LiaCommentsDollarSolid />}
                            label={
                              <div className="d-flex gap-2">
                                <del>AED {`${tourpkg.discount_price}`}</del>
                                <span className="touramount">AED {`${tourpkg.price}`}</span>
                              </div>
                            }
                          />
                        </div>
                      </div>
                    </Card>
                  </a>
                </Badge.Ribbon>
              </Col>
            ))}
          </Row>
        );
      })}
    </Container>
  );
};

export default TdtToursPackage;
