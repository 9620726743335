import { Button, Form, Input, Space } from 'antd';
import { FormInstance } from 'antd/es/form'; 
import TdtDrawer from 'components/websitelayout/TdtDrawer';
import React, { ChangeEvent, useEffect, useState } from 'react';  
import { Col, Row } from 'react-bootstrap';  
import {  message, Upload } from 'antd';  
import TdtInput from 'components/websitecomponents/TdtInput';
import TdtButton from 'components/websitecomponents/TdtButton'; 
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { WebApi } from 'shared/WebApi';
import TdtImage from 'components/websitecomponents/TdtImage';
import TdtSelect from 'components/websitecomponents/TdtSelect';
import TdtCKeditor from 'components/websitecomponents/TdtCKeditor';  
interface TdtBookDrwerprops {
  open?:boolean 
  onClose?:any 
  title?:string
  destroyOnClose?: boolean
  placement?:string
}  
const ViewProduct: React.FC<TdtBookDrwerprops> = (props) => {


  const [files, setFiles] = useState<File[]>([]); 

  const [form] = Form.useForm()
  const formRef = React.useRef<FormInstance>(null); 
  const [allCategories, setAllCategories] = useState<any[]>([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState<any[]>([]); 
  const [selectedCategoryTitle, setSelectedCategoryTitle] = useState<any[]>([]);  
  const [editorData, setEditorData] = useState<string>('');
  const [shorteditorData, setShortEditorData] = useState<string>('');

  const onFinish = async (values: any) => {  
    const formData = new FormData();
    files.forEach(file => {
      formData.append('package_slideImgsss[]', file);
      console.log(file.name)
    });  
    values.package_image = imageUpload
    values.package_slideImgs = packageSlideImages
    values.package_image_thumbnail = imageThumbnailUpload 
    values.transfer_type = transferType
    values.cat_id = selectedCategory.id
    values.cat_title = selectedCategory.name
    
    values.long_description = editorData
    values.description = shorteditorData
    values.duration_type= durationtype
    
    debugger
    try {
        const response = await WebApi('post', 'api/add_package' , values, 'multipart');  
        const responseData: any = response; 
        if (responseData.status === 200) { 
                message.success('Product added')  
                console.log('Error: Request error'+responseData.status);  
            }
        else {
          console.error('Error: Request error');  
        }
    } catch (error:any) {
        console.error('Error:', error);  
    }
  }; 
  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo); 
  };  

  // const packageSlideImagesFun = (e:any) => {
  //   if (e.target.files) {
  //     setFiles(Array.from(e.target.files));
  //   }
  // }; 
//   const packageSlideImagesFun = (cardIndex: number, e: ChangeEvent<HTMLInputElement>) => {
//     if (e.target.files && e.target.files.length > 0) {
//         const newFiles = [...files];
//         newFiles[cardIndex] = e.target.files[0];
//         packageImageUpload(newFiles);
//     }
// };



  const getCategories = async () => {   
    try {
      const response = await WebApi('get', 'api/get_all_categories');
      const responseData: any = response;
      if (responseData.status === 200) {
        
        const modifiedData = responseData.data.data.map((item: any) => ({
          label:  item.title,
          value:  item.cat_id,
          image:  item.image,
        }));

        setAllCategories(modifiedData);
        
      }
    } catch (error:any) {
      console.error('Error:', error);  
    } 
  }; 

  useEffect(() => { 
      getCategories()
  }, []) 

  const onChangeCategory = (value: string[]) => {
    setSelectedCategoryId(value);
    debugger
  };  

  const [selectedCategory, setSelectedCategory] = useState<{ id: string; name: string; image: string }>({
    id: '',
    name: '',
    image:''
  });
  
  const onChangeCategoryN = (value: string, option: any) => {
    if (option && option.label && option.image) {
      setSelectedCategory({
        id: value,
        name: option.label,
        image: option.image,
      });
    } else {
      console.error('Option object does not have the expected properties:', option);
    }
  };
 
  const [transferType, setTransferType] = useState<any[]>([]);
  const onChangeTransfertypeFun = (value: string[]) => {
    setTransferType(value);
  };

  const [durationtype, setDurationType] = useState<any[]>([]);  
  const onChangeDurationtypeFun = (value: string[]) => {
    setDurationType(value);
  };  
  const [imageUpload, packageImageUpload] = useState<File | null>(null);
  const packageImageUploadFun = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    packageImageUpload(file);   
  };

  const [imageThumbnailUpload, packageImageThumbnailUpload] = useState<File | null>(null);
    const packageImageThumbnailUploadFun = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    packageImageThumbnailUpload(file);   
  };
  const [packageSlideImages, setPackageSlideImages] =  useState<File[]>([]);
  const packageSlideImagesFun = ( e: ChangeEvent<HTMLInputElement>) => { 
    if (e.target.files && e.target.files.length > 0) { 
      const newFilesg = Array.from(e.target.files); // Convert FileList to array of File objects 
      setPackageSlideImages(prevFiles => {// Update state with the accumulated files 
          const updatedFiles = [...prevFiles, ...newFilesg];// Combine old files with new files 
          return updatedFiles;
      });
    } 
}; 
// const [packageAdditionalOpt, setPackageAdditionalOpt] =  useState<[]>([]);
// const packageAdditionalOptFun = ( e: ChangeEvent<HTMLInputElement>) => { 
//   if (e.target.value && e.target.value.length > 0) {  
//      setPackageAdditionalOpt 
//     })
// }   
 



  return (
    <TdtDrawer title={props.title} open={props.open} placement={props.placement} onClose={props.onClose} width={'1150px'} destroyOnClose={props.destroyOnClose}>      
      <Row>
        <Col sm={12}>
          <Form
          name="add_product"
          form={form}
          ref={formRef} 
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
          > 
          <Row>
              <Col sm={12} md={3}>
                  <TdtSelect label='Select Category' allowClear={false} optionList={allCategories} value={selectedCategory.name} onChange={onChangeCategoryN} />
              </Col> 
              <Col sm={12} md={3}>
                  <TdtInput label='Title' name='title' />
              </Col> 
              <Col sm={12} md={3}>
                  <TdtInput label='Price' name='price' />
              </Col>  
              <Col sm={12} md={3}>
                  <TdtInput label='Discount' name='discount_price' />
              </Col>  
              <Col sm={12} md={3}>
                  <TdtInput label='Child Price' name='child_price' />
              </Col> 
              <Col sm={12} md={3}>
                  <TdtInput label='Child Discount' name='child_discount_price' />
              </Col>  
              <Col sm={12} md={3}>
                  <TdtSelect label='Duration Type' defaultActiveFirstOption optionList={[ { value: 'days', label: 'Days' }, { value: 'hours', label: 'Hours' }  ]} value={durationtype} onChange={onChangeDurationtypeFun} />
              </Col> 
              <Col sm={12} md={3}>
                  <TdtInput label='Duration / Valid for' name='duration' placeholder='Number of days e.g 2,3' />
              </Col> 
              <Col sm={12} md={3}>
                  <TdtInput label='Rating' name='rating' />
              </Col> 
              <Col sm={12} md={3}>
                  <TdtInput label='Add Tags' name='tags' />
              </Col> 
              <Col sm={12} md={3}>
                  <TdtInput label='Pick up Time' name='pickup_time' placeholder='e.g: 8:30 to 9:00 AM'/>
              </Col> 
              
              
                
              {/* <Col sm={12}  md={3} className='d-flex flex-column'> 
                <label className='customlabel'>Product Image</label>
                <input type="file" onChange={packageImageUploadFun} />
              </Col>   */}
              <Col sm={12}  md={3} className='d-flex flex-column'> 
                <label className='customlabel'>Product Thumbnail</label>
                <input type="file" onChange={packageImageThumbnailUploadFun} />
              </Col> 
              <Col sm={12} md={3} className='d-flex flex-column'> 
              <label className='mb-2'>Add Package Images</label>
                <Form.List name="package_slideImgsO">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                          <Form.Item
                            {...restField}
                            name={[name, 'package_slideImgsf']}
                            rules={[{ required: true, message: 'Missing Package Image' }]}
                          >
                            <Input   type="file"  onChange={packageSlideImagesFun}  placeholder="add product Image" /> 
                          </Form.Item> 
                          <MinusCircleOutlined onClick={() => remove(name)} />
                        </Space> 
                      ))}
                      <Form.Item>
                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                          Add Images
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List> 
              </Col> 
              <Col sm={12} md={3}>
                  <TdtSelect label='Transport Type' defaultActiveFirstOption optionList={[ { value: 'sharing', label: 'Sharing' }, { value: 'private', label: 'Private' },  ]} value={transferType} onChange={onChangeTransfertypeFun} />
              </Col> 
              <Col sm={12} md={3}>
                  <TdtInput label='Transfer Adult Price' name='transfer_type_adultprice'placeholder='Price per person' />
              </Col>  
              <Col sm={12} md={3}>
                  <TdtInput label='Transfer Persons' name='transfer_type_persons' placeholder='Number of persons e.g 1, 2' />
              </Col>   
              <Col sm={12} md={3}>
                  <TdtInput label='Transfer Child Price' name='transfer_type_childprice' />
              </Col>   
              
              <Col sm={12} md={6} className='d-flex flex-column'> 
              <label className='mb-2'>Package Additional Options</label>
                <Form.List name="package_AdditionalOpt">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                          <Form.Item
                            {...restField}
                            name={[name, 'package_AdditionalOptTitle']}
                            rules={[{ required: true, message: 'Missing Additional Options Title' }]}
                          >
                            <Input type="text" placeholder="Package additional opt title " /> 
                          </Form.Item> 
                          <Form.Item
                            {...restField}
                            name={[name, 'package_AdditionalOptPrice']}
                            rules={[{ required: true, message: 'Missing Additional Options Price' }]}
                          >
                            <Input type="text" placeholder="Package additional opt price " /> 
                          </Form.Item> 
                          <Form.Item
                            {...restField}
                            name={[name, 'package_AdditionalOptPersons']}
                            rules={[{ required: true, message: 'Missing  number of persons additional opt' }]}
                          > 
                            <Input type="text" placeholder="Number of persons " /> 
                          </Form.Item> 
                          <MinusCircleOutlined onClick={() => remove(name)} />
                        </Space> 
                      ))}
                      <Form.Item>
                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                          Add Additional Options
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List> 
              </Col> 
              <Col sm={12}>
                <label className='pb-3'>Short Description</label>
                <TdtCKeditor data={shorteditorData} onChange={(data:string) => setShortEditorData(data)} />
              </Col> 
              <Col sm={12}>
                <label className='pb-3'>Detail Description</label>
                <TdtCKeditor data={editorData} onChange={(data:string) => setEditorData(data)} />
              </Col> 
                
              <Col sm={12} md={12}>
                  <TdtButton label={'Submit'} htmlType="submit" classNamebtn='me-2 px-5 mt-3'/> 
              </Col>  
          </Row>
          </Form>
        </Col>  
      </Row>
    </TdtDrawer>
  );
};

export default ViewProduct;
