import { CKEditor } from '@ckeditor/ckeditor5-react';
import { ClassicEditor, Bold, Essentials, Heading, Indent, IndentBlock, Italic, Link, List,  Undo,
  FontColor,
  FontBackgroundColor,
} from 'ckeditor5'; 
import 'ckeditor5/ckeditor5.css';

interface ckeditorProps {
  data?: string;
  onChange?: (data: string) => void;
}
export default function TdtCKeditor<ckeditorProps>(props:any) {  
  return (
        <CKEditor
        editor={ ClassicEditor }
        config={ {
          toolbar: [
            'undo', 'redo', '|',
            'heading', '|', 'bold','fontColor', 'fontBackgroundColor', 'italic', '|',
            'link',   'mediaEmbed', '|',
            'bulletedList', 'numberedList', 'indent', 'outdent'
          ],
          plugins: [ Bold, Essentials, Heading, Indent, IndentBlock, Italic, Link, List, 
            Undo,
            FontColor,
            FontBackgroundColor,
          ],
        } }
        data={props.data}
        onChange={(event, editor) => {
          const data = editor.getData();
          if (props.onChange) {
            props.onChange(data);
          }
        }}
       
      />
  );
}
 
 