import { Button, Form, Input, Space, message } from 'antd';
import { FormInstance } from 'antd/es/form';
import TdtDrawer from 'components/websitelayout/TdtDrawer';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import TdtInput from 'components/websitecomponents/TdtInput';
import TdtButton from 'components/websitecomponents/TdtButton';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { WebApi } from 'shared/WebApi';
import TdtSelect from 'components/websitecomponents/TdtSelect';
import TdtCKeditor from 'components/websitecomponents/TdtCKeditor';

interface TdtBookDrawerProps {
  open?: boolean;
  onClose?: any;
  title?: string;
  destroyOnClose?: boolean;
  placement?: string;
  editPackageId?: any;
}

const EditPackageDrawer: React.FC<TdtBookDrawerProps> = (props) => {
  const [files, setFiles] = useState<File[]>([]);
  const [form] = Form.useForm();
  const [allCategories, setAllCategories] = useState<any[]>([]);
  const [editorData, setEditorData] = useState<string>('');
  const [shorteditorData, setShortEditorData] = useState<string>('');
  const [packageData, setPackageData] = useState<any>(null);
  const [selectedCategory, setSelectedCategory] = useState({
    id: '',
    name: '',
    image: '',
  });
  const [transferType, setTransferType] = useState<any[]>([]);
  const [durationtype, setDurationType] = useState<any[]>([]);
  const [imageUpload, packageImageUpload] = useState<File | null>(null);
  const [imageThumbnailUpload, packageImageThumbnailUpload] = useState<File | null>(null);
  const [packageSlideImages, setPackageSlideImages] = useState<File[]>([]);

  const onFinish = async (values: any) => {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append('package_slideImgsss[]', file);
    });
    values.package_image = imageUpload;
    values.package_slideImgs = packageSlideImages;
    values.package_image_thumbnail = imageThumbnailUpload;
    values.transfer_type = transferType;
    values.cat_id = selectedCategory.id;
    values.cat_title = selectedCategory.name;
    values.long_description = editorData;
    values.description = shorteditorData;
    values.duration_type = durationtype;

    try {
      const response = await WebApi('post', `api/update_package/${props.editPackageId}`, values, 'multipart');
      const responseData: any = response;
      if (responseData.status === 200) {
        message.success('Product added');
      } else {
        console.error('Error: Request error');
      }
    } catch (error: any) {
      console.error('Error:', error);
    }
  };

  const getCategories = async () => {
    try {
      const response = await WebApi('get', 'api/get_all_categories');
      const responseData: any = response;
      if (responseData.status === 200) {
        const modifiedData = responseData.data.data.map((item: any) => ({
          label: item.title,
          value: item.cat_id,
          image: item.image,
        }));
        setAllCategories(modifiedData);
      }
    } catch (error: any) {
      console.error('Error:', error);
    }
  };

  const getPackageById = async () => {
    try {
      const response = await WebApi('get', `api/get_package_byId/${props.editPackageId}`);
      const responseData: any = response;

      if (responseData.status === 200) {
        const modifiedData = responseData.data.data;
        setPackageData(modifiedData);

        // Setting form fields after data is loaded
        form.setFieldsValue({
          cat_title: modifiedData.cat_title,
          title: modifiedData.title,
          price: modifiedData.price,
          discount_price: modifiedData.discount_price,
          child_price: modifiedData.child_price,
          child_discount_price: modifiedData.child_discount_price,
          duration_type: modifiedData.duration_type,
          duration: modifiedData.duration,
          rating: modifiedData.rating,
          transfer_type_adultprice: modifiedData.transfer_type_adultprice,
          transfer_type_childprice: modifiedData.transfer_type_childprice,
          transfer_type_persons: modifiedData.transfer_type_persons,
          tags: modifiedData.tags,
          pickup_time: modifiedData.pickup_time,
          long_description: modifiedData.long_description,
          description: modifiedData.description,
          // Setting Additional Options
          AdditionalOptions: Object.keys(modifiedData.AdditionalOptions).map((key) => ({
            title: modifiedData.AdditionalOptions[key].title,
            price: modifiedData.AdditionalOptions[key].price,
            persons: modifiedData.AdditionalOptions[key].persons,
          })),
          // Set SlideImages
          package_slideImgsO: modifiedData.SlideImages.map((image: string) => ({
            package_slideImgsf: image,
          })),
        });
      }
    } catch (error: any) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    getCategories();
    getPackageById();
  }, []);

  const packageImageThumbnailUploadFun = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;
    packageImageThumbnailUpload(file);
  };

  const packageSlideImagesFun = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const newFiles = Array.from(e.target.files);
      setPackageSlideImages((prevFiles) => [...prevFiles, ...newFiles]);
    }
  };
   
  const onChangeTransfertypeFun = (value: string[]) => {
    setTransferType(value);
  };
  
  return (
    <TdtDrawer
      title={props.title}
      open={props.open}
      placement={props.placement}
      onClose={props.onClose}
      width={'1150px'}
      destroyOnClose={props.destroyOnClose}
    >
      <Row>
        <Col sm={12}>
          <Form
            name="add_product"
            form={form}
            onFinish={onFinish}
            autoComplete="off"
            layout="vertical"
          >
            <Row>
              <Col sm={12} md={3}>
                <TdtSelect
                  label="Select Category"
                  allowClear={false}
                  optionList={allCategories}
                  name="cat_title"
                  onChange={(value: any) => setSelectedCategory(value)} value={undefined}                />
              </Col>
              <Col sm={12} md={3}>
                <TdtInput label="Title" name="title" />
              </Col>
              <Col sm={12} md={3}>
                <TdtInput label="Price" name="price" />
              </Col>
              <Col sm={12} md={3}>
                <TdtInput label="Discount" name="discount_price" />
              </Col>
              <Col sm={12} md={3}>
                <TdtInput label="Child Price" name="child_price" />
              </Col>
              <Col sm={12} md={3}>
                <TdtInput label="Child Discount" name="child_discount_price" />
              </Col>
              <Col sm={12} md={3}>
                <TdtSelect
                  label="Duration Type"
                  optionList={[
                    { value: 'days', label: 'Days' },
                    { value: 'hours', label: 'Hours' },
                  ]}
                  value={durationtype}
                  onChange={setDurationType}
                />
              </Col> 
              <Col sm={12} md={3}>
                  <TdtInput label='Duration / Valid for' name='duration' placeholder='Number of days e.g 2,3' />
              </Col>  
              <Col sm={12} md={3}>
                <TdtInput label="Rating" name="rating" />
              </Col>
              <Col sm={12} md={3}>
                <TdtInput label="Tags" name="tags" />
              </Col>
              <Col sm={12} md={3}>
                  <TdtInput label='Pick up Time' name='pickup_time' placeholder='e.g: 8:30 to 9:00 AM'/>
              </Col> 
              <Col sm={12} md={3}>
                <label className="customlabel">Product Thumbnail</label>
                <input type="file" onChange={packageImageThumbnailUploadFun} />
              </Col>
              <Col sm={12} md={3}> 
                <label className='mb-2 pt-3'>Add Package Images</label>
                <Form.List name="package_slideImgsO">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                          <Form.Item
                            {...restField}
                            name={[name, 'package_slideImgsf']}
                            rules={[{ required: true, message: 'Missing Package Image' }]}
                          >
                            <Input placeholder="Image filename or upload new image" />
                          </Form.Item>
                          <MinusCircleOutlined onClick={() => remove(name)} />
                        </Space>
                      ))}
                      <Form.Item>
                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                          Package Slide Images
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Col> 
              
              <Col sm={12} md={3}>
                  <TdtSelect label='Transport Type' defaultActiveFirstOption optionList={[ { value: 'sharing', label: 'Sharing' }, { value: 'private', label: 'Private' },  ]} value={transferType} onChange={onChangeTransfertypeFun} />
              </Col> 
              <Col sm={12} md={3}>
                  <TdtInput label='Transfer Adult Price' name='transfer_type_adultprice'placeholder='Price per person' />
              </Col>  
              <Col sm={12} md={3}>
                  <TdtInput label='Transfer Persons' name='transfer_type_persons' placeholder='Number of persons e.g 1, 2' />
              </Col>   
              <Col sm={12} md={3}>
                  <TdtInput label='Transfer Child Price' name='transfer_type_childprice' />
              </Col>  

              <Col sm={12}>
                <label className='pb-3'> Package Additional Options </label>
                <Form.List name="AdditionalOptions">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <Space key={key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                          <Form.Item
                            {...restField}
                            name={[name, 'title']}
                            rules={[{ required: true, message: 'Missing Title' }]}
                          >
                            <Input placeholder="Title" />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, 'price']}
                            rules={[{ required: true, message: 'Missing Price' }]}
                          >
                            <Input placeholder="Price" />
                          </Form.Item>
                          <Form.Item
                            {...restField}
                            name={[name, 'persons']}
                            rules={[{ required: true, message: 'Missing Persons' }]}
                          >
                            <Input placeholder="Persons" />
                          </Form.Item>
                          <MinusCircleOutlined onClick={() => remove(name)} />
                        </Space>
                      ))}
                      <Form.Item>
                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                          Add Additional Options 
                        </Button>
                      </Form.Item>
                    </>
                  )}
                </Form.List>
              </Col>
              <Col sm={12}> 
                <label className='pb-3'>Short Description</label>
                <TdtCKeditor
                  label="Short Description"
                  name="description"
                  data={shorteditorData}
                  onChange={(data: string) => setShortEditorData(data)}
                />
              </Col>
              <Col sm={12}>
              <label className='pb-3'>Long Description</label>
                <TdtCKeditor
                  label="Long Description"
                  name="long_description"
                  data={editorData}
                  onChange={(data: string) => setEditorData(data)}
                />
              </Col> 
              <Col sm={12}>
                <TdtButton   label="Update" htmlType="submit" classNamebtn='me-2 px-5 mt-3'/>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </TdtDrawer>
  );
};

export default EditPackageDrawer;
