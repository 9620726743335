import { FormInstance } from 'antd/es/form';  
import React, { useEffect, useState }  from 'react';  
import { Col, Row } from 'react-bootstrap';  
import {message, TableProps} from 'antd'; 
import TdtTable from 'components/websitecomponents/TdtTable';  
import TdtImage from 'components/websitecomponents/TdtImage'; 
import { WebApi } from 'shared/WebApi';
import TdtIcon from 'components/websitecomponents/TdtIcon';
import { FaPencil, FaRegTrashCan, FaTrashCan } from 'react-icons/fa6';
import EditPackageDrwr from './EditPackageDrwr';
const projectpath = process.env.REACT_APP_API_URL;

  
interface TdtBookDrwerprops {
  open?:boolean 
  onClose?:any 
  title?:string
  destroyOnClose?: boolean
  placement?:string
}  
const ViewProduct: React.FC<TdtBookDrwerprops> = (props) => { 
  const formRef = React.useRef<FormInstance>(null);  
  interface DataType {
    key: string;
    title: string;
    description: any; 
    price:string
    discount_price:string
    rating:string 
    package_image_thumbnail:string
    pickup_time:string
    pid:any 
  }

  const [editPackageDrwr, setEditPackageDrwr] = useState(false); 
  const [editPackageId, seteRditPackageId] = useState(false); 
  const editPackageFun = async (pid: any) => { 
    setEditPackageDrwr(true)
    seteRditPackageId(pid)
  };  

  
  const columns: TableProps<DataType>['columns'] = [
    {
      title: 'Category',
      dataIndex: 'cat_title',
      key: 'cat_title', 
    },
    {
      title: 'Package',
      dataIndex: 'title',
      key: 'title', 
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price', 
    },
    {
      title: 'Discounted Price',
      dataIndex: 'discount_price',
      key: 'discount_price', 
    },
    {
      title: 'Pickup Time',
      dataIndex: 'pickup_time',
      key: 'pickup_time', 
    },
    {
      title: 'Rating',
      dataIndex: 'rating',
      key: 'rating', 
    },  
    {
      title: 'Thumbnail',
      dataIndex: 'thumbnail',
      key: 'thumbnail',
      render: (_, record) => (
          <TdtImage src={projectpath+"public/panelassets/images/uploads/"+record.package_image_thumbnail} preview={false} width={'60px'}/>
      ),
    },
    {
      title: 'Action',
      key: 'action',
      render: (_, record) => (
          <div className='d-flex gap-2'><TdtIcon onClick={()=>deletePackage(record.pid)} icon={<FaRegTrashCan />}  /><TdtIcon onClick={()=>editPackageFun(record.pid)} icon={<FaPencil />}  /></div> 
      ),
    },
  ];  

  const deletePackage = async (pid: any) => {
    try {
      const response = await WebApi('post', `api/delete_package/${pid}`);
  
      const responseData: any = response;
      debugger
      if (responseData.status === 200) { 
        message.success('Package deleted successfully');
        getProducts();  
      }
    } catch (error:any) {
      console.error('Error:', error);  
    } 
  }; 
  

  const onClose = async (pid: any) => { 
    setEditPackageDrwr(false) 
  }; 

  const [getAllProducts, setGetAllProducts] = useState<any[]>([]);  
  const getProducts = async () => {
    try {
      const response = await WebApi('post', 'api/get_package');
      const responseData: any = response;
      if (responseData.status === 200) {
        const packagesArray = Object.values(responseData.data.data);
        setGetAllProducts(packagesArray);
        //Check Packages
        
      }
    } catch (error: any) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    getProducts();
  }, []); 

  return ( 
        <Row>
          {editPackageDrwr &&  <EditPackageDrwr open={editPackageDrwr} title='Edit Package' editPackageId={editPackageId} onClose={onClose}/>}
           
            <Col sm={12}>
                <TdtTable  pagination={false} tblheadcolumns={columns} tabledata={getAllProducts} />  
            </Col>  
        </Row> 
  );
}
 
export default React.memo(ViewProduct);