import TdtButton from 'components/websitecomponents/TdtButton';
import TdtIcon from 'components/websitecomponents/TdtIcon'; 
import React, { useEffect, useState } from 'react';

import { Collapse, Slider } from "antd"; 
import { Col, Container, Row } from 'react-bootstrap'; 
import { BsInfoCircle } from 'react-icons/bs';
import TdtDate from 'components/websitecomponents/TdtDate';
 
import { Checkbox} from 'antd'; 
import type { CollapseProps } from 'antd'; 
import { Link, useParams } from 'react-router-dom';
import { WebApi } from 'shared/WebApi';

const projectpath = process.env.REACT_APP_API_URL;

const CheckboxGroup = Checkbox.Group; 



 
interface DesertSafariprops {

}

interface PackageDataType {
  pid: number;
  cat_id: number; 
  title: string;
  description: string;
  price: number;
  discount_price: number;  
  duration: string; 
  package_image_thumbnail: string; 
} 

export const PackgesByCategories: React.FunctionComponent<DesertSafariprops> = (props) => {
  
  const plainOptions = ['Evening Safari', 'Morning Safari', 'Quad Biking','Morning Safar', 'Desert Resorts', 'Private Tours', 'Desert Resort', 'Executive', 'Combo Deals'];
  const [checkedList, setCheckedList] = useState<string[]>();
  const onChange = (list: string[]) => {
    setCheckedList(list);
    console.log('list: '+ list)
  };  
  const [value, setValue] = useState(0);
  

  const text = `A dog is a type of domesticated animal. Known for its loyalty and faithfulness,  it can be found as a welcome guest in many households across the world.`;
  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: 'Enchanting Overview of Desert Safari Dubai',
      children: <p>{text}</p>,
    },
    {
      key: '2',
      label: 'Types of Desert Safari | Sunrise Desert Safari | Morning Desert Safari | Evening Desert Safari | Premium Desert Safari | Luxury Desert Safari',
      children: <p>{text}</p>,
    } ,
    {
      key: '3',
      label: 'Sunrise Desert Safari | Morning Desert Safari | Evening Desert Safari | Premium Desert Safari | Luxury Desert Safari',
      children: <p>{text}</p>,
    } ,
    {
      key: '4',
      label: 'Morning Desert Safari | Evening Desert Safari | Premium Desert Safari | Luxury Desert Safari',
      children: <p>{text}</p>,
    } 
  ];

  const faqstems: CollapseProps['items'] = [
    {
      key: '1',
      label: 'Enchanting Overview of Desert Safari Dubai',
      children: <p>{text}</p>,
    },
    {
      key: '2',
      label: 'Types of Desert Safari | Sunrise Desert Safari | Morning Desert Safari | Evening Desert Safari | Premium Desert Safari | Luxury Desert Safari',
      children: <p>{text}</p>,
    } ,
    {
      key: '3',
      label: 'Morning Desert Safari | Evening Desert Safari | Premium Desert Safari | Luxury Desert Safari',
      children: <p>{text}</p>,
    } ,
    {
      key: '4',
      label: 'Sunrise Desert Safari | Morning Desert Safari | Evening Desert Safari | Premium Desert Safari | Luxury Desert Safari',
      children: <p>{text}</p>,
    } ,
    {
      key: '5',
      label: 'Evening Desert Safari | Premium Desert Safari | Luxury Desert Safari',
      children: <p>{text}</p>,
    } 
  ]; 


      
  const { id } = useParams(); 

  
  
  useEffect(() => { 
    getPackages_ByCatId(id);
  }, []);

  const [packageData, setPackageData] = useState<any[]>([]);
  const getPackages_ByCatId = async (id:any) => {   
    try { 
      const response = await WebApi('get', `api/get_packages_ByCatId/${id}`);  
     debugger
      const responseData: any = response;
      if (responseData.status === 200) { 
        const modifiedData  = responseData.data.data;  
        setPackageData(modifiedData);  
        debugger
    } 
    } catch (error:any) {
      console.error('Error:', error);  
    } 
  };
   
  return ( 
      <React.Fragment> 
        <Container fluid className='bannerBread py-5 position-relative' style={{backgroundImage:'url(http://localhost:3000/assets/toursbanners.jpg)'}}> 
          <Container className='bannerbredcontent'>
            <Row>
              <Col sm={12} className='py-5'>
                <h4 className='text-light'>Desert Safari Dubai Tours</h4>
              </Col>
            </Row>
          </Container>
          <div className='ovelaybred'></div>
        </Container> 

        <Container fluid className='position-relative py-5'> 
          <Container>
            <Row>
              <Col sm={12} md={3} className='drtsfrsidbar'>
                <div className='sidebartrours'>
                  <div className='amoutslidefltr'>
                    <h6>Select Price Range</h6> 
                    <div className="icon-wrapper"> 
                      <Slider  defaultValue={1130} {...props} onChange={setValue} value={value} max={5000} className='ms-0' /> 
                    </div>
                  </div>
                  <div className='amoutdatefltr'>
                    <h6 className='pt-3 pb-2'>Start date</h6> 
                      <TdtDate/> 
                  </div>
                  <div className='amoutdatefltr'>
                    <h6 className='pt-3 pb-2'>End date</h6> 
                      <TdtDate/> 
                  </div>
                  <div className='amoutcategoryfltr'>
                    <h6 className='pt-3 pb-2'>Select Subcategory </h6> 
                    <CheckboxGroup options={plainOptions} value={checkedList} onChange={onChange} />
                  </div>
                </div>
              </Col>
              <Col sm={12} md={9} className='listcrdcontent gap-5'>
                <React.Fragment>  
                  {packageData.map((item:any, i:any) => (   
                    <a  href={`/tours/${item.pid}`}  className='tourcardlist d-flex mb-3 ' >
                      <div className='imgcardlist'  style={{backgroundImage: `url( ${projectpath+"/public/panelassets/images/uploads/"+item.package_image_thumbnail} )`}}>
                      </div>
                      <div className='desftr w-100'>
                        <div className='descrdlst'>
                          <h5 className='pb-2'>{item.title}</h5>
                          <p className='m-0'>  
                            <div className='ckeditorpkgdtl'
                              dangerouslySetInnerHTML={{
                                __html: item.description.substring(0, 300) + (item.description.length > 200 ? "..." : "") ,
                              }}
                            />  
                          </p>
                        </div>
                        <div className='ftrcrdlst'>
                          <div className='cardlstbookbtn'><del>AED {item.discount_price}</del> <span className='amountcardlist'>AED {item.price}</span><TdtButton label={'Book Now'}/></div><div><span>Valid: For {item.duration} {item.duration_type}</span></div>
                        </div>
                      </div>
                      <div className='actionscardlist d-flex'>
                        <div className='iconsdiv'>
                          <TdtIcon icon={<BsInfoCircle/>} />
                          <TdtIcon icon={<BsInfoCircle/>} />
                        </div>
                        <div className='iconsdiv'>
                          <TdtIcon icon={<BsInfoCircle/>} />
                          <TdtIcon icon={<BsInfoCircle/>} />
                        </div>
                      </div>
                    </a>  
                  ))} 
                </React.Fragment>
                <div className='abouttourpkg pt-5'>
                  <h5 className='pb-3 text-center'>About Desert Safari Dubai Tours</h5>
                  <Collapse accordion items={items} defaultActiveKey={['1']} />
                </div>
                <div className='faqtourpkg pt-5'>
                  <h5 className='pb-3 text-center'>FAQs - Frequently Asked Questions about Desert Safari Dubai Tours</h5>
                  <Collapse accordion items={faqstems} defaultActiveKey={['1']} />
                </div>
              </Col>
            </Row>
          </Container>
        </Container>
      </React.Fragment>
  )
}

export default PackgesByCategories;
 