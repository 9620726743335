import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap'; 
import TdtIcon from './TdtIcon';
import { HiHeart, HiOutlineChatAlt, HiOutlineHeart, HiOutlinePaperAirplane, HiPaperAirplane } from 'react-icons/hi';
import { FaPlaneDeparture } from 'react-icons/fa6';
import { WebApi } from 'shared/WebApi';

interface Reasonstworkprops{
    reasonsToWorks?:any
    className?: string 
}

const ReasonsToWork: React.FC<Reasonstworkprops> = (props) => {
  const [getAllReasons, setGetAllReasons] = useState<any[]>([]);  
  const getReasons = async () => {   
    try {
      const response = await WebApi('get', 'api/get_all_reasons');
      const responseData: any = response;
      if (responseData.status === 200) {  
        const modifiedData:any = responseData.data.data  
        setGetAllReasons(modifiedData);  
        debugger
      }
    } catch (error:any) {
      console.error('Error:'+ error);  
    } 
  }; 
  useEffect(() => { 
    getReasons();
  }, []);

  
// alert(props.reasonsToWorks.whyyiconlabel)
  return (
    <React.Fragment>
        <Row className={`whysportrow py-3 my-5 ${props.className}`}> 
            <Col sm={12}  className='d-flex gap-3'> 
              <h4 className='whyustextheading'>
                Reasons to<br/> <span>Book With Us</span>
              </h4>  
            {getAllReasons.map((item:any, i:any) => (
              <TdtIcon
                key={i}
                icon={<><TdtIcon icon={<FaPlaneDeparture/>} /></>}
                label={item.reason}
                classNamelinkdiv='seticonhome' 
                classNameicon='seticonhomewhy' 
                classNamelbl='setlblhomewhy'
              />
            ))}
           

            </Col> 
        </Row> 
        
    </React.Fragment>
  );
}

export default ReasonsToWork;